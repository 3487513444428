import React, { createContext, useCallback, useContext, ReactNode, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { IonRouterLink } from '@ionic/react';
import { useAuth } from './AuthProvider';

// Context for navigation services
interface NavigationContextType {
  impersonateUID: string | null;
  setImpersonation: (uid: string | null) => void;
  getAppLink: (path: string) => string;
  clearImpersonation: () => void;
}

const NavigationContext = createContext<NavigationContextType>({
  impersonateUID: null,
  setImpersonation: () => {},
  getAppLink: (path) => path,
  clearImpersonation: () => {},
});

// Helper function to parse query parameters
function getQueryParams(search: string): Record<string, string> {
  const params: Record<string, string> = {};
  const searchParams = new URLSearchParams(search);
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
}

// Helper function to build query string
function buildQueryString(params: Record<string, string>): string {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    searchParams.append(key, value);
  });
  return searchParams.toString();
}

// Provider component
export const NavigationProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const { isAdmin } = useAuth();
  
  // Parse query parameters from location
  const queryParams = getQueryParams(location.search);
  const impersonateUID = queryParams.impersonate || null;
  
  const setImpersonation = useCallback((uid: string | null) => {
    if (!isAdmin) return;
    
    const newParams = { ...queryParams };
    
    if (uid) {
      newParams.impersonate = uid;
    } else {
      delete newParams.impersonate;
    }
    
    const queryString = buildQueryString(newParams);
    history.push({
      pathname: location.pathname,
      search: queryString ? `?${queryString}` : ''
    });
  }, [isAdmin, queryParams, history, location.pathname]);
  
  const clearImpersonation = useCallback(() => {
    if (!isAdmin) return;
    
    const newParams = { ...queryParams };
    delete newParams.impersonate;
    
    const queryString = buildQueryString(newParams);
    history.push({
      pathname: location.pathname,
      search: queryString ? `?${queryString}` : ''
    });
  }, [isAdmin, queryParams, history, location.pathname]);
  
  const getAppLink = useCallback((path: string) => {
    if (!impersonateUID || !isAdmin) return path;
    
    // If path already has query parameters
    if (path.includes('?')) {
      return `${path}&impersonate=${impersonateUID}`;
    }
    
    return `${path}?impersonate=${impersonateUID}`;
  }, [impersonateUID, isAdmin]);
  
  return (
    <NavigationContext.Provider value={{
      impersonateUID,
      setImpersonation,
      getAppLink,
      clearImpersonation
    }}>
      {children}
    </NavigationContext.Provider>
  );
};

// Custom hook to use the navigation context with proper type handling
export const useNavigation = () => {
  const context = useContext(NavigationContext);
  return {
    ...context,
    // Ensure impersonateUID is string | undefined for DataProvider compatibility
    impersonateUIDForProvider: context.impersonateUID || undefined
  };
};

// Custom link component that preserves impersonation
export const AppLink: React.FC<React.ComponentProps<typeof IonRouterLink>> = ({ 
  routerLink, 
  children,
  ...props 
}) => {
  const { getAppLink } = useNavigation();
  
  return (
    <IonRouterLink 
      routerLink={routerLink ? getAppLink(routerLink) : undefined} 
      {...props}
    >
      {children}
    </IonRouterLink>
  );
}; 