import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonRow } from "@ionic/react";
import Tooltip from "../components/Tooltip";

import './About.css';

const About:React.FC = ()=>{
  return <IonCard>
    <IonCardContent>
      <p style={{width:"100%", display:"flex", justifyContent:"center"}}>
        <IonButton routerLink="/class/demo" color="success" style={{margin:"auto", fontSize:"1.5em"}}>Take the Demo Quiz</IonButton>
      </p>
      <h1>Standards-Aligned Financial Literacy Assessments</h1>
      <a href="https://FinLitRI.org">FinLitRI.org</a> provides an assortment of assessments aligned with <a href="https://www.councilforeconed.org/national-standards-for-personal-financial-education/">RI-adopted national standards for financial literacy education</a><Tooltip helpText="Alternate Link: https://www.jumpstart.org/what-we-do/support-financial-education/standards-2021/"></Tooltip>, which were drafted through a partnership between the <a href="https://www.ride.ri.gov/">Rhode Island Department of Education</a>, the <a href="https://rhodeisland.councilforeconed.org/">Council for Economic Education</a>, and the <a href="https://rijumpstart.org/ri-jumpstart/">Jump$tart Coalition</a>. FinLitRI.org is officially recommended by the Rhode Island Department of Education as a source of proficiency assessments in their <a href="https://ride.ri.gov/sites/g/files/xkgbur806/files/2023-09/Financial%20Literacy%20Guidance%20%28September%202023%29%20for%20Publication.pdf">Proficiency Implementation Guide</a>.

      <IonGrid>
        <IonRow>
          <IonCol>
            <a href="https://www.ride.ri.gov/"><img alt="RIDE Logo" className="logo" src="./assets/RIDE.png" style={{backgroundColor:"var(--ion-color-primary)", padding:"1em"}}/></a>
          </IonCol>
          <IonCol>
            <a href="https://rijumpstart.org/ri-jumpstart/"><img alt="Jump$tart Logo" className="logo" src="./assets/RI-Jumpstart-Logo.png"/></a>
          </IonCol>
          <IonCol>
            <a href="https://rhodeisland.councilforeconed.org/"><img alt="CEE Logo" className="logo" src="./assets/logo-ri-cee.gif"/></a>
          </IonCol>
        </IonRow>
      </IonGrid>

      <p>FinLitRI.org provides topical assessments for each of the 6 core financial literacy pillars (Earning Income, Spending, Saving, Investing, Managing Credit, and Managing Risk), as well as cumulative diagnostic assessments useful for benchmarking purposes. Each assessment item (question) has been reviewed for relevance and efficacy in distinguishing student understandings of the content associated with each standard.</p>

      <p>These assessments are useful as diagnostic quizzes (in the form of pre-tests), formative assessments for identifying difficulties, and as cumulative assessments (in the form of post-tests). These can be used as measures of learning, tools for innovation in teaching practice, and as records of accountability to <a href="http://webserver.rilin.state.ri.us/BillText/BillText19/SenateText19/S0112A.pdf">Rhode Island state legislation</a>.</p>

      <h1>Backed by a Community of Practice</h1>
      <p>FinLitRI.org was developed with content from the <a href="https://www.cfasociety.org/providence">Chartered Financial Analyst (CFA) Society Providence</a>, in partnership with the <a href="https://www.fpari.org/">Financial Planning Association (FPA) Rhode Island</a>. The CFA Society Providence (established 1947) is a non-profit that exists to provide relevant financial education to members and stakeholders, and conducts educational outreach to universities and high schools throughout Rhode Island. Similarly, FPA Rhode Island is invested in improving access to financial literacy education. Furthermore, the content of FinLitRI.org has the backing of business schools and student organizations across the universities of Rhode Island.</p>
      {/* , and in collaboration with the <a href="https://www.cfasociety.org/pittsburgh/Pages/default.aspx">CFA Society Pittsburgh</a> */}

      <IonGrid>
        <IonRow>
          <IonCol>
            <a href="https://www.cfasociety.org/providence"><img alt="CFA Providence Logo" className="big-logo" src="./assets/CFA_Providence.png"/></a>
          </IonCol>
          <IonCol>
            <a href="https://www.fpari.org/"><img alt="FPA RI Logo" className="big-logo" src="./assets/FPARI.png"/></a>
          </IonCol>
        </IonRow>
        {/* <IonRow>
          <IonCol>
            <a href="https://www.fpari.org/"><img alt="FPA RI Logo" className="logo" src="./assets/FPARI.png"/></a>
          </IonCol>
          <IonCol>
            <a href="https://www.cfasociety.org/pittsburgh/Pages/default.aspx"><img alt="CFA Pittsburgh Logo" className="logo" src="./assets/CFA_Pittsburgh.jpg"/></a>
          </IonCol>
        </IonRow> */}
        <IonRow>
          <IonCol>
            <a href="https://www.uri.edu/"><img alt="URI Logo" className="logo" src="./assets/URI.png"/></a>
          </IonCol>
          <IonCol>
            <a href="https://www.providence.edu/"><img alt="PC Logo" className="logo" src="./assets/PC.jpg"/></a>
          </IonCol>
          <IonCol>
            <a href="https://salve.edu/"><img alt="Salve Regina Logo" className="logo" src="./assets/Salve.png"/></a>
          </IonCol>
          <IonCol>
            <a href="https://www.ric.edu/"><img alt="RIC Logo" className="logo" src="./assets/RIC.jpg"/></a>
          </IonCol>
          <IonCol>
            <a href="https://www.jwu.edu/"><img alt="JWU Logo" className="logo" src="./assets/JWU.jpg"/></a>
          </IonCol>
        </IonRow>
      </IonGrid>

      <h1>Built Using Research-Backed Methods</h1>

      <p>Using techniques developed in partnership with <a href="https://sam-saarinen.github.io/artifacts/papers/LAK_2020.pdf">Brown University</a>, FinLitRI.org features state-of-the-art predictive algorithms for modeling student knowledge. By adaptively selecting assessment items based on a student's previous responses, FinLitRI.org is able to rapidly home in on a student's current level of understanding. Using similar technology to the GRE and other adaptive tests, FinLitRI.org is able to provide precise and calibrated maps of students' knowledge frontiers.</p>

      <p>Additionally, FinLitRI.org is designed to protect student privacy, and is FERPA-compliant by design. No personally identifying information of students is ever uploaded to the system. FinLitRI.org is built on Google Cloud Services (reliable with 99.9% uptime) with support provided by <a href="https://edapt.tech">Edapt Technologies</a>, a Rhode-Island-based company. See the <a href="https://ride.ri.gov/press-releases/ride-publishes-new-financial-literacy-resources-schools">statewide announcement</a> for a mention of these organizations.</p>

      <IonGrid>
        <IonRow>
          <IonCol>
            <a href="https://brown.edu"><img alt="Brown University Logo" className="logo" src="./assets/brown.png"/></a>
          </IonCol>
          <IonCol>
            <a href="https://edapt.tech"><img alt="Edapt Technologies Logo" className="logo" src="./assets/edapt.png"/></a>
          </IonCol>
        </IonRow>
      </IonGrid>

      <p>For help or to report a bug, please reach out to <a href="mailto:contact@edapt.app">contact@edapt.app</a></p>
      <p>For more resources from RIDE, visit their official page at <a href="https://ride.ri.gov/instruction-assessment/financial-literacy">this link</a>.</p>
    </IonCardContent>
  </IonCard>
}

export default About;