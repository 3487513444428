import * as XLSX from 'xlsx';

/**
 * Converts a table to CSV format
 * @param headers Array of header strings
 * @param rows Array of row data arrays
 * @returns CSV string
 */
export const tableToCSV = (headers: string[], rows: (string | number)[][]): string => {
  const csvRows = [
    headers.join(','),
    ...rows.map(row => 
      row.map(cell => 
        typeof cell === 'string' && cell.includes(',') 
          ? `"${cell}"` 
          : String(cell)
      ).join(',')
    )
  ];
  
  return csvRows.join('\n');
};

/**
 * Converts a table to HTML format
 * @param headers Array of header strings
 * @param rows Array of row data arrays
 * @returns HTML string
 */
export const tableToHTML = (headers: string[], rows: (string | number)[][]): string => {
  const htmlTable = `
    <table>
      <thead>
        <tr>
          ${headers.map(header => `<th>${header}</th>`).join('')}
        </tr>
      </thead>
      <tbody>
        ${rows.map(row => `
          <tr>
            ${row.map(cell => `<td>${cell}</td>`).join('')}
          </tr>
        `).join('')}
      </tbody>
    </table>
  `;
  
  return htmlTable;
};

/**
 * Copies table data to clipboard in a format that preserves table structure
 * @param headers Array of header strings
 * @param rows Array of row data arrays
 * @returns Promise that resolves when copy is complete
 */
export const copyTableToClipboard = async (
  headers: string[], 
  rows: (string | number)[][]
): Promise<void> => {
  try {
    // Create HTML table for rich text copying
    const htmlTable = tableToHTML(headers, rows);
    const csvData = tableToCSV(headers, rows);
    
    // Create a blob with HTML content
    const htmlBlob = new Blob([htmlTable], { type: 'text/html' });
    const csvBlob = new Blob([csvData], { type: 'text/plain' });
    
    // Use the Clipboard API with multiple formats
    await navigator.clipboard.write([
      new ClipboardItem({
        'text/html': htmlBlob,
        'text/plain': csvBlob
      })
    ]);
    
    return Promise.resolve();
  } catch (err) {
    console.error('Failed to copy table data:', err);
    
    // Fallback to plain text if the rich copy fails
    try {
      const csvData = tableToCSV(headers, rows);
      await navigator.clipboard.writeText(csvData);
      return Promise.resolve();
    } catch (fallbackErr) {
      console.error('Fallback copy also failed:', fallbackErr);
      return Promise.reject(fallbackErr);
    }
  }
};

/**
 * Downloads table data as CSV file
 * @param headers Array of header strings
 * @param rows Array of row data arrays
 * @param filename Filename without extension
 */
export const downloadTableAsCSV = (
  headers: string[], 
  rows: (string | number)[][], 
  filename: string
): void => {
  const csv = tableToCSV(headers, rows);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${filename}.csv`);
  link.style.visibility = 'hidden';
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Downloads table data as XLSX file
 * @param headers Array of header strings
 * @param rows Array of row data arrays
 * @param filename Filename without extension
 * @param sheetName Name of the worksheet
 */
export const downloadTableAsXLSX = (
  headers: string[], 
  rows: (string | number)[][], 
  filename: string,
  sheetName: string = 'Sheet1'
): void => {
  // Create worksheet data with headers
  const wsData = [
    headers,
    ...rows
  ];
  
  // Create worksheet
  const ws = XLSX.utils.aoa_to_sheet(wsData);
  
  // Create workbook
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  
  // Generate and download file
  XLSX.writeFile(wb, `${filename}.xlsx`);
}; 