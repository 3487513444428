import { IonApp, setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global-ionic.css'; // Add some stylings to make ionic cards nicer on desktop.

import { initializeApp } from "firebase/app";
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import AppRouter from './AppRouter';
import AuthProvider from './services/AuthProvider';
import DataProvider from './services/DataProvider';

setupIonicReact();

// const firebaseConfig = {
//   apiKey: "AIzaSyA4UlhoZLSnaMLctrjV2-ttfb_W_mfVg_Q",
//   authDomain: "edapt-app.firebaseapp.com",
//   projectId: "edapt-app",
//   storageBucket: "edapt-app.appspot.com",
//   messagingSenderId: "593303529751",
//   appId: "1:593303529751:web:8ac163d4031f6914676f52",
//   measurementId: "G-3XQHVM8D9T"
// };
const firebaseConfig = {
  apiKey: "AIzaSyAOpfDOru4gfi3_xHvQw-1Nr6ZhYQXObmU",
  authDomain: "quizius-2024.firebaseapp.com",
  databaseURL: "https://quizius-2024-default-rtdb.firebaseio.com",
  projectId: "quizius-2024",
  storageBucket: "quizius-2024.appspot.com",
  messagingSenderId: "1019775907490",
  appId: "1:1019775907490:web:5ee12f688055c7788689fb",
  measurementId: "G-875XMC403G"
};

const firebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp);
if (window.location.hostname.toLowerCase() === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001); //TODO: Remove
}
// NOTE: Below polyfill won't work because Ionic serves http, not https.
// const functions = getFunctions(firebaseApp);
// //Note: Code to connect (and test) connection to functions emulator should be called in main.tsx
// const polyFillFunctions = async ()=>{
//   if (window.location.hostname.toLowerCase() === 'localhost') {
//     try {
//       const response = await fetch('http://localhost:5001');
//       if (response.ok) {
//         connectFunctionsEmulator(functions, 'localhost', 5001);
//         console.log('Connected to Firebase Functions emulator.');
//       } else {
//         console.warn('Functions emulator not detected, using production.');
//       }
//     } catch (error) {
//       console.error('Failed to connect to Functions emulator:', error);
//       // Proceed with production functions as fallback
//       console.log('Using production functions calls as fallback.');
//     }
//   }
// }
export {firebaseApp, functions}//, firebaseFunctions}; // Since it's pretty unimaginable to be running two firebase APIs in one frontend, this wil just be a global export, not a context provider.
// However, uses of the object (auth, firestore) will have related context providers.

const App: React.FC = () => {
  return (
    <AuthProvider>
      <DataProvider>
        <IonApp>
          <AppRouter />
        </IonApp>
      </DataProvider>
    </AuthProvider>
  );
};

export default App;

