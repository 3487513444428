import React from 'react';
import { IonButton, IonIcon, IonToast } from '@ionic/react';
import { copyOutline, downloadOutline } from 'ionicons/icons';
import { copyTableToClipboard, downloadTableAsCSV, downloadTableAsXLSX } from '../utils/tableExport';

interface TableActionsProps {
  headers: string[];
  rows: (string | number)[][];
  filename: string;
}

const TableActions: React.FC<TableActionsProps> = ({ headers, rows, filename }) => {
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState('');
  
  const handleCopy = async () => {
    try {
      await copyTableToClipboard(headers, rows);
      setToastMessage('Table copied to clipboard');
      setShowToast(true);
    } catch (err) {
      setToastMessage('Failed to copy table');
      setShowToast(true);
    }
  };
  
  const handleDownloadCSV = () => {
    downloadTableAsCSV(headers, rows, filename);
  };
  
  const handleDownloadXLSX = () => {
    downloadTableAsXLSX(headers, rows, filename);
  };
  
  return (
    <div className="table-actions" style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
      <IonButton size="small" onClick={handleCopy}>
        <IonIcon icon={copyOutline} slot="start" />
        Copy
      </IonButton>
      <IonButton size="small" onClick={handleDownloadCSV}>
        <IonIcon icon={downloadOutline} slot="start" />
        CSV
      </IonButton>
      <IonButton size="small" onClick={handleDownloadXLSX}>
        <IonIcon icon={downloadOutline} slot="start" />
        XLSX
      </IonButton>
      
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        position="bottom"
      />
    </div>
  );
};

export default TableActions; 