import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterLink,
  IonRow,
  IonText,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { addOutline, addSharp, analyticsOutline, analyticsSharp, archiveOutline, archiveSharp, bookmarkOutline, checkboxOutline, checkboxSharp, fingerPrintOutline, fingerPrintSharp, heartOutline, heartSharp, helpOutline, libraryOutline, librarySharp, logInOutline, logInSharp, mailOutline, mailSharp, nutritionOutline, nutritionSharp, paperPlaneOutline, paperPlaneSharp, personOutline, personSharp, settingsOutline, settingsSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Menu.css';
import { useAuth } from '../services/AuthProvider';
import LogOutButton from './LogOutButton';
import { useData } from '../services/DataProvider';
import { isAdminEmail } from '../../../data-types/Types';
import { useNavigation } from '../services/NavigationService';
import { useState } from 'react';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const infoLinks = new Map<string, string>([["about", "/about"],["privacy","/privacy"],["terms","/terms"]])

// Create a custom IonItem that preserves impersonation
const AppMenuItem: React.FC<{
  className?: string;
  routerLink: string;
  routerDirection?: "none" | "forward" | "back" | "root";
  lines?: "full" | "inset" | "none";
  detail?: boolean;
  children: React.ReactNode;
}> = ({ className, routerLink, routerDirection, lines, detail, children }) => {
  const { getAppLink } = useNavigation();
  
  return (
    <IonItem 
      className={className} 
      routerLink={getAppLink(routerLink)} 
      routerDirection={routerDirection} 
      lines={lines} 
      detail={detail}
    >
      {children}
    </IonItem>
  );
};

const Menu: React.FC = () => {
  // TODO: Nested/Contextual menu items? Active assignments or combined overview/review page?

  const location = useLocation();
  const {currentUser, studentSignIns, isAdmin} = useAuth();
  const {synching, teacherClasses, checkClassCode, classCodes} = useData();
  const { impersonateUID, setImpersonation, clearImpersonation } = useNavigation();
  const [impersonateInput, setImpersonateInput] = useState('');

  // Add this line to check if we're on localhost
  const isLocalhost = window.location.hostname === 'localhost';

  const appPages:AppPage[] = [];
  if (currentUser?.email === "sam@edapt.app") {
    appPages.push({
      title:"standards",
      url:"/create-standard",
      iosIcon:checkboxOutline,
      mdIcon:checkboxSharp,
    });
  }
  if (!currentUser) {
    if(studentSignIns.length > 0) {
      studentSignIns.forEach((studentEnrollment)=>{
        if (!synching){
          checkClassCode(studentEnrollment.classCode);
        }
        appPages.push({
          title:classCodes.get(studentEnrollment.classCode)?.displayName || ("Class ID: " + studentEnrollment.classCode),
          url:"/class/"+studentEnrollment.classCode,
          iosIcon:libraryOutline,
          mdIcon:librarySharp,
        })
      });
      appPages.push(
        {
          title:"add a class",
          url: "/class",
          iosIcon: addOutline,
          mdIcon: addSharp
        }
      );
    } else {
      appPages.push(
        {
          title:"student login",
          url: "/class",
          iosIcon: logInOutline,
          mdIcon: logInSharp
        }
      );
      // appPages.push(
      //   {
      //     title:"teacher login",
      //     url: "/teacher-login",
      //     iosIcon: logInOutline,
      //     mdIcon: logInSharp
      //   }
      // );
    }
  }
  if (currentUser) {
    for (let teacherClass of teacherClasses) {
      appPages.push(
        {
          title:`${teacherClass.displayName}`,
          url:`/manage-class/${teacherClass.shortCode}`,
          iosIcon: nutritionOutline,
          mdIcon: nutritionSharp
        }
      )
    }

    if (currentUser.email === "sam@edapt.app") {
      appPages.push(
        {
          title: "Admin Analytics",
          url:"/analytics",
          iosIcon: analyticsOutline,
          mdIcon: analyticsSharp,
        }
      );
      appPages.push(
        {
          title: "Quiz Management",
          url: "/admin/quizzes",
          iosIcon: libraryOutline,
          mdIcon: librarySharp,
        }
      );
    }

    // Modify this section to only add "create a class" when on localhost
    if (isLocalhost) {
      appPages.push(
        {
          title:"create a class",
          url:"/create-class",
          iosIcon: addOutline,
          mdIcon: addSharp
        }
      )
    }

    appPages.push(
      {
        title:"account settings",
        url: "/teacher-account-settings",
        iosIcon: fingerPrintOutline,
        mdIcon: fingerPrintSharp
      }
    )
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonGrid><IonRow><IonCol style={{maxWidth:"4em"}}><IonImg src="./assets/icon/icon.png"/></IonCol>
            <IonCol>
              <IonGrid>
                <IonRow><IonListHeader>Fin Lit RI</IonListHeader></IonRow>
                <IonRow><IonNote style={{margin:"0", width:"100%", textAlign:"left"}}>{currentUser? currentUser.email: ""}</IonNote></IonRow>
                <IonRow>{currentUser || studentSignIns.length > 0? <IonNote style={{margin:"0", width:"100%", textAlign:"left"}}><LogOutButton /></IonNote>: <></>}</IonRow>
              </IonGrid>
            </IonCol>
          </IonRow></IonGrid>
          
          {/* Admin Impersonation UI */}
          {isAdmin && (
            <IonCard className="ion-margin">
              <IonCardHeader>
                <IonCardTitle>Admin Tools</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                {impersonateUID ? (
                  <>
                    <IonNote>Currently viewing as: {impersonateUID}</IonNote>
                    <IonButton 
                      expand="block"
                      color="medium"
                      onClick={() => clearImpersonation()}
                      className="ion-margin-top"
                    >
                      <IonIcon slot="start" icon={personOutline} />
                      Exit Impersonation
                    </IonButton>
                  </>
                ) : (
                  <>
                    <IonInput
                      placeholder="User ID to impersonate"
                      value={impersonateInput}
                      onIonChange={e => setImpersonateInput(e.detail.value || '')}
                    />
                    <IonButton 
                      expand="block"
                      onClick={() => setImpersonation(impersonateInput)}
                      disabled={!impersonateInput}
                      className="ion-margin-top"
                    >
                      <IonIcon slot="start" icon={personSharp} />
                      View as User
                    </IonButton>
                  </>
                )}
              </IonCardContent>
            </IonCard>
          )}
          
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <AppMenuItem 
                  className={location.pathname === appPage.url ? 'selected' : ''} 
                  routerLink={appPage.url} 
                  routerDirection="none" 
                  lines="none" 
                  detail={false}
                >
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </AppMenuItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="labels-list">
          <IonListHeader>info pages</IonListHeader>
          {Array.from(infoLinks.entries()).map(([label, address], index) => (
            <AppMenuItem lines="none" key={index} routerLink={address}>
              <IonIcon slot="start" icon={helpOutline} />
              <IonLabel>{label}</IonLabel>
            </AppMenuItem>
          ))}
        </IonList>
        <br />
        <p><a href="mailto:contact@edapt.app">Experiencing a bug? Email Support.</a></p>
        <IonText color="medium" style={{fontSize:".7em"}}><p>All site content copyright © Edapt Technologies, 2022<br/> unless otherwise noted.</p></IonText>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
