import React, { useState, useMemo } from 'react';
import { useData } from '../../services/DataProvider';
import { Quiz } from '../../../../data-types/Types';
import { useAuth } from '../../services/AuthProvider';
import { Redirect } from 'react-router-dom';
import { IonContent, IonHeader, IonToolbar, IonTitle, IonList, IonItem, IonLabel, IonButton, IonInput, IonCard, IonCardContent, IonCardHeader, IonSpinner, IonNote } from '@ionic/react';

export const QuizManagement: React.FC = () => {
    const { quizzes, adminCreateRecord } = useData();
    const { isAdmin } = useAuth();
    const [editingQuiz, setEditingQuiz] = useState<Quiz | null>(null);
    const [newSubQuizId, setNewSubQuizId] = useState<string>('');

    // Create a memoized map of quiz IDs to names
    const quizNameMap = useMemo(() => {
        const map: Record<string, string> = {};
        quizzes?.forEach(quiz => {
            if (quiz.id) {
                map[quiz.id] = quiz.name;
            }
        });
        return map;
    }, [quizzes]);

    if (!isAdmin) {
        return <Redirect to="/" />;
    }

    if (!quizzes) {
        return (
            <div className="ion-text-center ion-padding">
                <IonSpinner />
                <p>Loading quizzes...</p>
            </div>
        );
    }

    const handleSave = async (quiz: Quiz) => {
        try {
            await adminCreateRecord('finlitri-quizzes', quiz);
            setEditingQuiz(null);
        } catch (error) {
            console.error('Failed to save quiz:', error);
            alert('Failed to save quiz. Please try again.');
        }
    };

    const handleEdit = (quiz: Quiz) => {
        setEditingQuiz({...quiz});
    };

    const handleCreate = () => {
        const newQuiz: Quiz = {
            name: 'New Quiz',
            subquizIDs: [],
        };
        setEditingQuiz(newQuiz);
    };

    const handleAddSubQuiz = () => {
        if (!editingQuiz || !newSubQuizId.trim()) return;
        
        const updatedQuiz = {
            ...editingQuiz,
            subquizIDs: [...(editingQuiz.subquizIDs || []), newSubQuizId.trim()]
        };
        setEditingQuiz(updatedQuiz);
        setNewSubQuizId('');
    };

    const handleRemoveSubQuiz = (subQuizId: string) => {
        if (!editingQuiz) return;
        
        const updatedQuiz = {
            ...editingQuiz,
            subquizIDs: (editingQuiz.subquizIDs || []).filter(id => id !== subQuizId)
        };
        setEditingQuiz(updatedQuiz);
    };

    return (
        <IonContent>
            <IonButton expand="block" className="ion-margin" onClick={handleCreate}>
                Create New Quiz
            </IonButton>

            {editingQuiz && (
                <IonCard className="ion-margin">
                    <IonCardHeader>
                        <IonTitle>{editingQuiz.id ? 'Edit Quiz' : 'New Quiz'}</IonTitle>
                        {editingQuiz.id && (
                            <IonNote className="ion-padding-start">ID: {editingQuiz.id}</IonNote>
                        )}
                    </IonCardHeader>
                    <IonCardContent>
                        <IonItem>
                            <IonLabel position="stacked">Name</IonLabel>
                            <IonInput 
                                value={editingQuiz.name}
                                onIonChange={e => setEditingQuiz({
                                    ...editingQuiz, 
                                    name: e.detail.value || ''
                                })}
                            />
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">Sub-quizzes</IonLabel>
                            <div className="ion-padding-vertical">
                                <IonList>
                                    {(editingQuiz.subquizIDs || []).map((subQuizId) => (
                                        <IonItem key={subQuizId}>
                                            <IonLabel>
                                                <h3>{quizNameMap[subQuizId] || 'Unknown Quiz'}</h3>
                                                <IonNote>ID: {subQuizId}</IonNote>
                                            </IonLabel>
                                            <IonButton 
                                                slot="end"
                                                fill="clear"
                                                color="danger"
                                                onClick={() => handleRemoveSubQuiz(subQuizId)}
                                            >
                                                Remove
                                            </IonButton>
                                        </IonItem>
                                    ))}
                                </IonList>
                            </div>
                            <div className="ion-margin-top">
                                <IonInput
                                    placeholder="Add sub-quiz ID"
                                    value={newSubQuizId}
                                    onIonChange={e => setNewSubQuizId(e.detail.value || '')}
                                />
                                <IonButton 
                                    size="small"
                                    onClick={handleAddSubQuiz}
                                    disabled={!newSubQuizId.trim()}
                                >
                                    Add Sub-quiz
                                </IonButton>
                            </div>
                        </IonItem>

                        <div className="ion-margin-top">
                            <IonButton expand="block" onClick={() => handleSave(editingQuiz)}>
                                Save
                            </IonButton>
                            <IonButton expand="block" fill="clear" onClick={() => setEditingQuiz(null)}>
                                Cancel
                            </IonButton>
                        </div>
                    </IonCardContent>
                </IonCard>
            )}

            <IonList>
                {quizzes.map((quiz) => (
                    <IonItem key={quiz.id}>
                        <IonLabel>
                            <h2>{quiz.name}</h2>
                            <IonNote>ID: {quiz.id}</IonNote>
                            <p>{quiz.subquizIDs?.length || 0} sub-quizzes</p>
                            {quiz.subquizIDs && quiz.subquizIDs.length > 0 && (
                                <div className="ion-padding-vertical">
                                    <IonList>
                                        {quiz.subquizIDs.map(id => (
                                            <IonItem key={id} lines="none">
                                                <IonLabel>
                                                    <h3>{quizNameMap[id] || 'Unknown Quiz'}</h3>
                                                    <IonNote>ID: {id}</IonNote>
                                                </IonLabel>
                                            </IonItem>
                                        ))}
                                    </IonList>
                                </div>
                            )}
                        </IonLabel>
                        <IonButton slot="end" onClick={() => handleEdit(quiz)}>
                            Edit
                        </IonButton>
                    </IonItem>
                ))}
            </IonList>
        </IonContent>
    );
};

export default QuizManagement; 