import { IonButton, IonContent } from "@ionic/react";
import { useAuth } from "../services/AuthProvider";

const AdminLogin: React.FC = () => {
  const { signInWithGoogle, signInWithMicrosoft } = useAuth();

  return (
    <IonContent>
      <div className="ion-padding">
        <IonButton 
          expand="block"
          onClick={signInWithGoogle}
          color="light"
        >
          Sign in with Google
        </IonButton>
        
        <IonButton 
          expand="block"
          onClick={signInWithMicrosoft}
          color="light"
          className="ion-margin-top"
        >
          Sign in with Microsoft
        </IonButton>
      </div>
    </IonContent>
  );
};

export default AdminLogin; 