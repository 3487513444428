import { IonButton, IonInput, IonItem, IonLabel, IonText, IonSelect, IonSelectOption } from "@ionic/react";
import { ChangeEvent, FormEvent, useRef, useState } from "react";
import { useData } from "../services/DataProvider";

const CreateQuestion:React.FC<{defaultValues: any}> = ({defaultValues, ...props})=>{
  const {adminCreateRecord, synchingAdmin, quizzes} = useData();

  const [distractors, setDistractors] = useState<string[]>(defaultValues.distractors || []);
  const [prompt, setPrompt] = useState<string>(defaultValues.prompt || "");
  const [correctAnswer, setCorrectAnswer] = useState<string>(defaultValues.correctAnswer || "");
  const [standardsCodes, setStandardsCodes] = useState<string[]>(defaultValues.standardsCodes || []);
  const [selectedQuizID, setSelectedQuizID] = useState<string>(defaultValues.quizID || "");
  const [questionType, setQuestionType] = useState<string>(defaultValues.questionType || "FCQ");
  const [errorMsg, setErrorMsg] = useState("");

  function handleChange(event:FormEvent<HTMLIonInputElement>, index:number) {
    let vals = [...distractors];
    vals[index] = event.currentTarget.value?.toString() || "";
    setDistractors(vals);
  }

  const addClick = () => {
    setDistractors([...distractors, ''])
  }

  const removeClick = (event:any, index:number) => {
    let vals = [...distractors];
    vals.splice(index, 1);
    setDistractors(vals );
  }

  const handleSubmit = (event:FormEvent) => {
    event.preventDefault();
    setErrorMsg("");
    if (prompt.length < 10 || correctAnswer.length < 1 || distractors.length < 1) {
      setErrorMsg("The prompt, correct answer, and at least one distractor must be filled in.")
    } else {
      let record = {
        prompt, correctAnswer, distractors, standardsCodes, questionType, selectedQuizID
      }
      adminCreateRecord("finlitri-questions", record).then(()=>{
        setPrompt("");
        setCorrectAnswer("");
        setDistractors([]);
      }).catch((reason)=>{setErrorMsg(reason)});
    }
  }

  return (
    <form onSubmit={handleSubmit}>
        {errorMsg? <IonText color="danger"><p>{errorMsg}</p></IonText> : <></>}
        <IonItem>
          <IonLabel position="floating">Prompt</IonLabel>
          <IonInput value={prompt} type="text" onInput={(e)=>{setPrompt(e.currentTarget.value?.toString() || "")}}/>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Correct Answer</IonLabel>
          <IonInput value={correctAnswer} type="text" onInput={(e)=>{setCorrectAnswer(e.currentTarget.value?.toString() || "")}}/>
        </IonItem>
        {distractors.map((el, i) => (<div key={i}>
          <IonItem key={i}>
            <IonLabel position="floating">Incorrect Answer {i + 1}</IonLabel>
            <IonInput type="text" value={el||''} onInput={(event) => {handleChange(event, i)}} />
            <IonButton onClick={event => {removeClick(event, i)}} >Remove</IonButton>
          </IonItem>
        </div>))
        }
        <IonButton onClick={addClick}>add more</IonButton>
        <IonButton type="submit" disabled={synchingAdmin}>Submit</IonButton>
    </form>
  );
}

export default CreateQuestion