import { IonPage, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router";
import CreateClass from "./components/CreateClass";
import ForgotPassword from "./components/ForgotPassword";
import LogIn from "./components/LogIn";
import Menu from "./components/Menu";
import SignUp from "./components/SignUp";
import About from "./pages/About";
import AccountManagement from "./pages/AccountManagement";
import MenuPage from "./pages/MenuPage";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import { useAuth } from "./services/AuthProvider";
import ClassManagementGuard from "./pages/ClassManagementGuard";
import ClassSelector from "./components/ClassSelector";
import StudentClassView from "./pages/StudentClassView";
import CreateStandard from "./components/CreateStandard";
import Analytics from "./pages/Analytics";
import AdminLogin from "./components/AdminLogin";
import QuizManagement from "./components/admin/QuizManagement";
import { NavigationProvider, useNavigation } from "./services/NavigationService";
import DataProvider from "./services/DataProvider";

// Wrapper component that provides the impersonation context to DataProvider
const AppContent: React.FC = () => {
  const { currentUser, isAdmin } = useAuth();
  const { impersonateUID } = useNavigation();
  
  // Only allow impersonation for admins and convert null to undefined
  const effectiveUID = isAdmin && impersonateUID ? impersonateUID : undefined;

  return (
    <DataProvider customUID={effectiveUID}>
      <IonSplitPane contentId="main">
        <Menu />
        <IonPage id="main">
          <Switch>
            <Route path="/" exact={true}>
              <Redirect to="/about" />
            </Route>

            <Route path="/create-standard" exact={true}>
              {!(currentUser?.email === "sam@edapt.app")? <Redirect to="/" /> : <MenuPage title="add a standard"><CreateStandard /></MenuPage>}
            </Route>

            <Route path="/create-class" exact={true}>
              {!currentUser? <Redirect to="/" /> : <MenuPage title="create a class"><CreateClass /></MenuPage>}
            </Route>
            <Route path="/teacher-account-settings" exact={true}>
              {!currentUser? <Redirect to="/" /> : <MenuPage title="account settings"><AccountManagement /></MenuPage>}
            </Route>
            <Route path="/manage-class/:classID" exact={true}>
              {!currentUser? <Redirect to="/" /> : <ClassManagementGuard />}
            </Route>

            <Route path="/class" exact={true}>
              <MenuPage title="student login"><ClassSelector /></MenuPage>
            </Route>
            <Route path="/class/:classCode">
              <StudentClassView/>
            </Route>

            <Route path="/analytics" exact={true}>
              <MenuPage title="admin analytics"><Analytics /></MenuPage>
            </Route>

            <Route path="/teacher-login" exact={true}>
              {currentUser? <Redirect to="/" /> : <MenuPage title="teacher login"><LogIn /></MenuPage>}
            </Route>
            <Route path="/teacher-signup" exact={true}>
              {currentUser? <Redirect to="/" /> : <MenuPage title="teacher signup"><SignUp /></MenuPage>}
            </Route>
            <Route path="/forgot-password" exact={true}>
              {currentUser? <Redirect to="/" /> : <MenuPage title="teacher - reset password"><ForgotPassword /></MenuPage>}
            </Route>

            <Route path="/about" exact>
              <MenuPage title="about"><About /></MenuPage>
            </Route>
            <Route path="/privacy" exact>
              <MenuPage title="privacy policies"><Privacy /></MenuPage>
            </Route>
            <Route path="/terms" exact>
              <MenuPage title="terms and conditions"><Terms /></MenuPage>
            </Route>

            <Route path="/admin-login" exact={true}>
              {currentUser?.email === "sam@edapt.app" ? 
                <Redirect to="/analytics" /> : 
                <MenuPage title="admin login"><AdminLogin /></MenuPage>
              }
            </Route>

            <Route path="/admin/quizzes" exact={true}>
              {currentUser?.email === "sam@edapt.app" ? 
                <MenuPage title="quiz management"><QuizManagement /></MenuPage> : 
                <Redirect to="/" />
              }
            </Route>

            <Route>{/* Default catch-all route at end. */}
              <MenuPage title="404 - Not Found"></MenuPage>
            </Route>
          </Switch>
        </IonPage>
      </IonSplitPane>
    </DataProvider>
  );
};

const AppRouter: React.FC = () => {
  return (
    <IonReactRouter>
      <NavigationProvider>
        <AppContent />
      </NavigationProvider>
    </IonReactRouter>
  );
};

export default AppRouter;