import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonLabel, IonList, IonListHeader, IonText } from "@ionic/react";
import { Assignment, Quiz, StudentEnrollmentFromServer, StudentStatusFromServer } from "../../../data-types/Types";
import { useData } from "../services/DataProvider";
import CreateAssignment from "./CreateAssignment";
import Expandable from "./Expandable";
import Standard, { formatStandardCode } from "./Standard";
import TableActions from './TableActions';

// Define explicit types for the summary object
type AssignmentSummary = {
  completed: number;
  quizName: string | undefined;
  suggestedTopics: [string, number][];
  studentAverages: {[studentID: string]: number};
  standardAverages: {[standardID: string]: number};
  inferredKnowledgeByStandard: {[studentID: string]: {[standardID: string]: number}};
};

const ClassManagement: React.FC<{classID: string}> = ({classID, ...props}) => {
  const link = (window.location.protocol + "//") + window.location.hostname.toLowerCase() + (window.location.port ? ":" + window.location.port : "") + "/class/" + classID;
  const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

  const {standards, teacherData} = useData();

  const enrolledStudents = (teacherData.classesToEnrollments?.[classID] || []) as StudentEnrollmentFromServer[];
  const assignments = (teacherData.classesToAssignments?.[classID] || []) as Assignment[];

  // Create assignment summaries using the data from teacherData.assignmentsToMasteryData
  const assignmentsToSummaries: Record<string, AssignmentSummary> = Object.fromEntries(assignments.map((assignment) => {
    const statuses = (teacherData.assignmentsToStatuses?.[assignment.id || ""] || []) as StudentStatusFromServer[];
    const quizName = teacherData.quizzes?.filter((quiz: Quiz) => quiz.id?.trim() === assignment.quizID.trim())?.[0]?.name;
    
    // Extract topic recommendations from statuses
    const nextTopicCounts = new Map<string, number>();
    const masteredTopicCounts = new Map<string, number>();
    
    for (let status of statuses) {
      if (status.status === "complete") {
        for (let nextStandard of status.nextStandardsToReview) {
          nextTopicCounts.set(nextStandard, (nextTopicCounts.get(nextStandard) || 0) + 1);
        }
        for (let masteredStandard of status.completedStandardIDs) {
          masteredTopicCounts.set(masteredStandard, (masteredTopicCounts.get(masteredStandard) || 0) + 1);
        }
      }
    }
    
    const suggestedNextTopics = [...nextTopicCounts.entries()].sort((a, b) => b[1] - a[1]);
    
    // Get mastery data from the server
    const masteryData = teacherData.assignmentsToMasteryData?.[assignment.id || ""];
    
    return [assignment.id, {
      completed: statuses.length,
      quizName: quizName,
      suggestedTopics: suggestedNextTopics.slice(0, 3),
      studentAverages: masteryData?.studentAverages || {},
      standardAverages: masteryData?.standardAverages || {},
      inferredKnowledgeByStandard: masteryData?.inferredKnowledgeByStandard || {}
    }];
  }));

  return <>
    <IonCard>
      <IonCardHeader><IonCardTitle>Class Overview and Students</IonCardTitle></IonCardHeader>
      <IonCardContent>
        <p>Link for students: <a href={link}>{link}</a></p>
        <h2>Enrolled Students: {enrolledStudents.length}</h2>
        <Expandable>
          <IonText color="danger"><p>For data privacy reasons, you will not be able to identify students with their FinLitRI.org IDs except by communicating with them outside of this system.</p></IonText>
          <IonListHeader><IonLabel>Anonymous Student IDs</IonLabel></IonListHeader>
          <IonList>
            {enrolledStudents.map((enrolledStudent, index) => <IonItem key={index}>{enrolledStudent.studentPassword}</IonItem>)}
          </IonList>
        </Expandable>
      </IonCardContent>
    </IonCard>

    {isLocalhost && (
      <IonCard>
        <IonCardHeader><IonCardTitle>Create New Assignment</IonCardTitle></IonCardHeader>
        <IonCardContent>
          <Expandable>
            <CreateAssignment classID={classID}/>
          </Expandable>
        </IonCardContent>
      </IonCard>
    )}

    {assignments.map((assignment, index) => {
      const summary = assignmentsToSummaries[assignment.id || ""];
      
      return <IonCard key={assignment.id || index}>
        <IonCardHeader>
          <IonCardTitle>{assignment.title || ("Assigned Quiz: " + (summary?.quizName || assignment.quizID))}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          {assignment.description ? <p><b>Description: </b>{assignment.description}</p> : <></>}
          <p>Completed by <b>{summary?.completed}</b> of <b>{enrolledStudents.length}</b>.</p>

          <br />

          <h1>Classroom Recommendations</h1>
          {summary?.suggestedTopics.length > 0 ? <>
            <h2>Suggested Next Topics</h2>
            {summary?.suggestedTopics.map((nextTopic: [string, number], index: number) => {
              const standardID = nextTopic[0];
              const numberOfStudents = nextTopic[1];
              const standard = standards.find(standard => standard.id === standardID);
              
              return <div key={index}>
                {standard ? (
                  <Standard standard={standard}></Standard>
                ) : (
                  <p><b>Unknown Standard:</b> {standardID}</p>
                )}
                <p>Needed and accessible for {numberOfStudents} students.</p>
              </div>
            })}
          </>
          :
          <><p>None. (It looks like there aren't any students with knowledge gaps in this data so far.)</p></>
          }
          
          {/* Display student performance data */}

          <h2>Student Inferred Mastery</h2>
          <Expandable>
            <TableActions 
              headers={['Student ID', 'Average Knowledge']}
              rows={Object.entries(summary?.studentAverages || {}).map(([studentID, average]) => [
                studentID, 
                `${(average * 100).toFixed(1)}%`
              ])}
              filename={`${assignment.title || summary?.quizName || 'assignment'}_student_mastery`}
            />
            <div style={{ overflowX: 'auto' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Student ID</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Average Knowledge</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(summary?.studentAverages || {}).map(([studentID, average]) => (
                    <tr key={studentID}>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{studentID}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                        {(average * 100).toFixed(1)}%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Expandable>

          <h2>Average Mastery by Standard</h2>
          <Expandable>
            <TableActions 
              headers={['Standard', 'Average Knowledge']}
              rows={Object.entries(summary?.standardAverages || {})
                .sort(([_, dataA], [__, dataB]) => dataB - dataA)
                .map(([standardID, average]) => {
                  const standard = standards.find(s => s.id === standardID);
                  return [
                    standard ? formatStandardCode(standard) + " (" + standard.title + ")" : standardID,
                    `${(average * 100).toFixed(1)}%`
                  ];
                })}
              filename={`${assignment.title || summary?.quizName || 'assignment'}_standard_mastery`}
            />
            <div style={{ overflowX: 'auto' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Standard</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Average Knowledge</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(summary?.standardAverages || {})
                    .sort(([_, dataA], [__, dataB]) => dataB - dataA)
                    .map(([standardID, average]) => {
                      const standard = standards.find(s => s.id === standardID);
                      return (
                        <tr key={standardID}>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                            {standard ? formatStandardCode(standard) + " (" + standard.title + ")" : standardID}
                          </td>
                          <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                            {(average * 100).toFixed(1)}%
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Expandable>

          <h2>Inferred Student Knowledge per (Assessed) Standard</h2>
          <Expandable>
            <p style={{fontSize: "small", fontStyle: "italic"}}>Note: Scores have been adjusted for estimated likelihoods of guessing and making mistakes across questions. Inferred mastery scores may change over time as more data allows higher-precision estimates.</p>
            <TableActions 
              headers={[
                'Student ID',
                ...Object.keys(summary?.standardAverages || {}).map(standardID => {
                  const standard = standards.find(s => s.id === standardID);
                  return standard ? formatStandardCode(standard) + " (" + standard.title + ")" : standardID;
                })
              ]}
              rows={Object.entries(summary?.inferredKnowledgeByStandard || {}).map(([studentID, standardValues]) => [
                studentID,
                ...Object.keys(summary?.standardAverages || {}).map(standardID => 
                  standardValues[standardID] === undefined ? '-' : `${(standardValues[standardID] * 100).toFixed(1)}%`
                )
              ])}
              filename={`${assignment.title || summary?.quizName || 'assignment'}_detailed_mastery`}
            />
            <div style={{ overflowX: 'auto' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Student ID</th>
                    {Object.keys(summary?.standardAverages || {}).map(standardID => {
                      const standard = standards.find(s => s.id === standardID);
                      return (
                        <th key={standardID} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
                          {standard ? formatStandardCode(standard) + " (" + standard.title + ")" : standardID}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(summary?.inferredKnowledgeByStandard || {}).map(([studentID, standardValues]) => (
                    <tr key={studentID}>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{studentID}</td>
                      {Object.keys(summary?.standardAverages || {}).map(standardID => (
                        <td key={standardID} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                          {standardValues[standardID] === undefined ? '-' : 
                           (standardValues[standardID] * 100).toFixed(1) + '%'}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Expandable>
        </IonCardContent>
      </IonCard>
    })}
  </>
}

export default ClassManagement;