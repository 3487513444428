import { jsonEval } from "@firebase/util";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonInput, IonItem, IonLabel, IonList, IonNote, IonSelect, IonSelectOption, IonText, IonTextarea } from "@ionic/react";
import { stringify } from "querystring";
import { FormEvent, useRef, useState, useMemo } from "react";
import { EducationalStandard, ServerFCQ } from "../../../data-types/Types";
import { useData } from "../services/DataProvider";
import CreateQuestion from "./CreateQuestion";
import DisplayFCQ from "./DisplayFCQ";
import Expandable from "./Expandable";
import Standard from "./Standard";

const CreateStandard:React.FC = ({ ...props})=>{
  const {synchingAdmin, standards, adminCreateRecord, questions, quizzes} = useData();
  const standardRef = useRef<HTMLIonInputElement>(null);
  const substandardRef = useRef<HTMLIonInputElement>(null);
  const gradeLevelRef = useRef<HTMLIonSelectElement>(null);
  const titleRef = useRef<HTMLIonInputElement>(null);
  const descriptionRef = useRef<HTMLIonTextareaElement>(null);
  const topicSelectRef = useRef<HTMLIonSelectElement>(null);

  const [error, setError] = useState("");

  // Add new state for filters
  const [selectedGrade, setSelectedGrade] = useState<string>("");
  const [selectedPillar, setSelectedPillar] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const topicQuizIDs = {
    "4": {
      "I. Earning Income": "yfUPIKYDK7Q7gPZhhwIW",
      "II. Spending": "w6Ky1miYyeDyqL2q2d2H", 
      "III. Saving": "ood6qfCWAcwbyr6SCcQi",
      "IV. Investing": "FrBvtLW5pvFm9E6goBzi",
      "V. Managing Credit": "EUDCyCe7rOGgi9KkpWKt",
      "VI. Managing Risk": "5w9Pm74NlFSOewcu2xsj"
    },
    "8": {
      "I. Earning Income": "hFo5y7X3pli2iXOE7qqB",
      "II. Spending": "QARrHEfsOBTIzEw8Ishw", 
      "III. Saving": "vD8j3IWPJ7WdYDMGryOe",
      "IV. Investing": "n48EFoQwWhUf39RDOGRo",
      "V. Managing Credit": "iTB9H61oiEiNTY07s6A3",
      "VI. Managing Risk": "SiXY5Rj4ic690lxzsLtN"
    },
    "12": {
      "I. Earning Income": "ZmQZtYyOu6zCmqKy0oNF",
      "II. Spending": "wflAiJqOFTA7IGSMU0BU", 
      "III. Saving": "sshfwH3eNEcEGmblusDD",
      "IV. Investing": "T4Ki1Jj4hKFcwwgPtnyu",
      "V. Managing Credit": "KLlJ4vaBGlpzDKe1XRMt",
      "VI. Managing Risk": "oxZy6OAohlpwbRXByi9E"
    },
  };

  const topics = Object.keys(topicQuizIDs[12]);

  // Replace useEffect with useMemo for filtering
  const filteredStandards = useMemo(() => {
    let filtered = [...standards];
    
    if (selectedGrade) {
      filtered = filtered.filter(standard => standard.grade === selectedGrade);
    }
    
    if (selectedPillar) {
      filtered = filtered.filter(standard => standard.topic === selectedPillar);
    }
    
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(standard => {
        // Check standard title and description
        if (standard.title?.toLowerCase().includes(searchLower) ||
            standard.description?.toLowerCase().includes(searchLower)) {
          return true;
        }
        
        // Check associated questions
        const standardQuestions = questions.filter(q => 
          q.standardsCodes?.includes(standard.id || "")
        );
        
        return standardQuestions.some(question => 
          question.prompt?.toLowerCase().includes(searchLower) ||
          question.correctAnswer?.toLowerCase().includes(searchLower) ||
          question.distractors?.some(answer => 
            answer.toLowerCase().includes(searchLower)
          )
        );
      });
    }
    
    return filtered;
  }, [standards, selectedGrade, selectedPillar, searchTerm, questions]);

  async function handleSubmit(event:FormEvent) {
    event.preventDefault(); // Prevents page from refreshing on form submit.
    // TODO
    setError("");
    const standardIndex = standardRef.current?.value;
    const substandardIndex = substandardRef.current?.value;
    const title = titleRef.current?.value;
    const description = descriptionRef.current?.value;
    const selectedTopic = topicSelectRef.current?.value;
    const gradeLevel = gradeLevelRef.current?.value;

    if (!standardIndex || !substandardIndex || !title || !description || !selectedTopic || !gradeLevel) {
      setError("All fields must be filled.");
      return;
    }

    const record:EducationalStandard = {
      title: "" + title,
      description: description,
      year:2021,
      region:"RI",
      subject:"FinLit",
      grade: gradeLevel,
      topic: selectedTopic,
      standardIndex:"" + standardIndex,
      substandardIndex:"" + substandardIndex,
      documentURL:"https://www.councilforeconed.org/national-standards-for-personal-financial-education/",
    }
    // record.id = `${record.region}.${record.year}.${record.subject}.${record.grade}.${record.topic}.${record.standardIndex}${record.substandardIndex}`;
    // Decided to just use default id in the database, but render codes in the display as appropriate.

    await adminCreateRecord("finlitri-standards", record);
    standardRef.current.value = "";
    substandardRef.current.value = "";
    titleRef.current.value = "";
    descriptionRef.current.value = "";
  }

  const structuredQuestions = new Map<string, ServerFCQ[]>(); // Map from standard id to list of applicable questions
  const unassignedQuestions = [] as ServerFCQ[];
  questions.forEach((question)=>{
    const questionStandards = question.standardsCodes || [];
    if (questionStandards.length < 1) {
      unassignedQuestions.push(question);
    } else {
      questionStandards.forEach((standard) => {
        const questionList = structuredQuestions.get(standard) || [];
        questionList.push(question)
        structuredQuestions.set(standard, questionList);
      });
    }
  });

  const getQuizzesForStandard = (standardId: string) => {
    return quizzes.filter(quiz => quiz.standardIDs?.includes(standardId));
  };

  const addQuizToStandard = async (standardId: string, quizId: string) => {
    const quiz = quizzes.find(q => q.id === quizId);
    if (!quiz) return;

    const newStandardIDs = [...new Set([...(quiz.standardIDs || []), standardId])];
    await adminCreateRecord("finlitri-quizzes", {
      ...quiz,
      standardIDs: newStandardIDs
    });
  };

  const removeQuizFromStandard = async (standardId: string, quizId: string) => {
    const quiz = quizzes.find(q => q.id === quizId);
    if (!quiz) return;

    const newStandardIDs = (quiz.standardIDs || []).filter(id => id !== standardId);
    await adminCreateRecord("finlitri-quizzes", {
      ...quiz,
      standardIDs: newStandardIDs
    });
  };

  const addQuizToQuestion = async (questionId: string, quizId: string) => {
    const quiz = quizzes.find(q => q.id === quizId);
    if (!quiz) return;

    const newQuestionIDs = [...new Set([...(quiz.questionIDs || []), questionId])];
    await adminCreateRecord("finlitri-quizzes", {
      ...quiz,
      questionIDs: newQuestionIDs
    });
  };

  const removeQuizFromQuestion = async (questionId: string, quizId: string) => {
    const quiz = quizzes.find(q => q.id === quizId);
    if (!quiz) return;

    const newQuestionIDs = (quiz.questionIDs || []).filter(id => id !== questionId);
    await adminCreateRecord("finlitri-quizzes", {
      ...quiz,
      questionIDs: newQuestionIDs
    });
  };

  const getQuizzesForQuestion = (questionId: string) => {
    return quizzes.filter(quiz => quiz.questionIDs?.includes(questionId));
  };

  // Helper function to compare arrays as sets
  const areArraysEqual = (arr1: string[], arr2: string[]) => {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    if (set1.size !== set2.size) return false;
    return [...set1].every(value => set2.has(value));
  };

  return <>
    <IonCard>
      <IonCardContent>
        <h2>Filter Standards</h2>
        <IonItem>
          <IonLabel>Grade Level</IonLabel>
          <IonSelect 
            value={selectedGrade} 
            onIonChange={e => setSelectedGrade(e.detail.value)}
          >
            <IonSelectOption value="">All Grades</IonSelectOption>
            {["4", "8", "12"].map((grade) => (
              <IonSelectOption key={grade} value={grade}>Grade {grade}</IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>

        <IonItem>
          <IonLabel>Financial Literacy Pillar</IonLabel>
          <IonSelect 
            value={selectedPillar} 
            onIonChange={e => setSelectedPillar(e.detail.value)}
          >
            <IonSelectOption value="">All Pillars</IonSelectOption>
            {topics.map((topic) => (
              <IonSelectOption key={topic} value={topic}>{topic}</IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>

        <IonItem>
          <IonLabel position="floating">Search Standards</IonLabel>
          <IonInput
            value={searchTerm}
            onIonChange={e => setSearchTerm(e.detail.value || "")}
            placeholder="Search by title or description..."
          />
        </IonItem>
      </IonCardContent>
    </IonCard>

    <IonCard>
      <IonCardContent>
        <p>Create New Standard: <Expandable>
          <h3>Create Standard</h3>
          <p>Content is based on the RI-adopted Financial Literacy Standards.</p>
          {!!error? <IonText color={'danger'}><h3>{error}</h3></IonText>: <></>}
          <form onSubmit={handleSubmit}>
            <IonItem>
              <IonLabel>Financial Literacy Pillar</IonLabel>
              <IonSelect ref={topicSelectRef}>
                {topics.map((topic, index)=>{return <IonSelectOption key={index}>{topic}</IonSelectOption>})}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel>Grade Level for Standards</IonLabel>
              <IonSelect ref={gradeLevelRef}>
                {["4", "8", "12"].map((grade, index)=>{return <IonSelectOption key={index}>{grade}</IonSelectOption>})}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Standard Number</IonLabel>
              <IonInput type="text" ref={standardRef}/>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Substandard Letter/Number</IonLabel>
              <IonInput type="text" ref={substandardRef}/>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Title/Topic</IonLabel>
              <IonInput type="text" ref={titleRef}/>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Description</IonLabel>
              <IonTextarea ref={descriptionRef} autoGrow/>
            </IonItem>
            <IonButton expand="block" type="submit" className="ion-margin-top" disabled={synchingAdmin}>
              Create
            </IonButton>
          </form>

          <h3 style={{ marginTop: '2rem' }}>Database Management</h3>
          <h4>Export Database</h4>
          <p>Download a JSON file containing all standards, questions, and quizzes.</p>
          <IonButton expand="block" onClick={() => {
            const exportData = {
              standards: standards,
              questions: questions,
              quizzes: quizzes
            };
            const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportData, null, 2));
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute("href", dataStr);
            downloadAnchorNode.setAttribute("download", "edapt_database_export.json");
            document.body.appendChild(downloadAnchorNode);
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
          }}>
            Export Database
          </IonButton>

          <h4 style={{ marginTop: '1rem' }}>Import Database</h4>
          <p>Import standards, questions, and quizzes from a previously exported JSON file.</p>
          <IonItem>
            <IonLabel position="stacked">Select JSON File to Import</IonLabel>
            <input
              type="file"
              accept=".json"
              style={{ marginTop: '0.5rem' }}
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onload = async (e) => {
                    try {
                      const importData = JSON.parse(e.target?.result as string);
                      // Import standards
                      for (const standard of importData.standards) {
                        await adminCreateRecord("finlitri-standards", standard);
                      }
                      // Import questions
                      for (const question of importData.questions) {
                        await adminCreateRecord("finlitri-questions", question);
                      }
                      // Import quizzes
                      for (const quiz of importData.quizzes) {
                        await adminCreateRecord("finlitri-quizzes", quiz);
                      }
                      setError("Import completed successfully!");
                    } catch (err) {
                      setError("Error importing data: " + err);
                    }
                  };
                  reader.readAsText(file);
                }
              }}
            />
          </IonItem>
        </Expandable></p>
      </IonCardContent>
    </IonCard>

    <IonCard>
      <IonCardHeader><IonCardTitle>Summary Stats</IonCardTitle></IonCardHeader>
      <IonCardContent>
        <p><b>Filtered Standards: </b> {filteredStandards.length}</p>
        <p><b>Total Standards: </b> {standards.length}</p>
        <p><b>Total Questions: </b> {questions.length}</p>
        <p><b>Total Quizzes: </b> {quizzes.length}</p>
      </IonCardContent>
    </IonCard>

    {filteredStandards.map((standard, index)=>{
      return <IonCard key={index}>
        <IonCardContent>
          <Standard standard={standard} showID/>
          {standard.id && <>
            <h4>Associated Quizzes:</h4>
            <p><b>Default Quiz: </b> 
              {quizzes.find(q => q.id === topicQuizIDs[(standard.grade as keyof typeof topicQuizIDs)][(standard.topic as keyof typeof topicQuizIDs["12"])])?.name || "None"}
            </p>
            
            <IonList>
              {getQuizzesForStandard(standard.id).map((quiz) => (
                <IonItem key={quiz.id}>
                  <IonLabel>
                    <h3>{quiz.name}</h3>
                    <IonNote>ID: {quiz.id}</IonNote>
                  </IonLabel>
                  <IonButton 
                    slot="end"
                    fill="clear"
                    color="danger"
                    onClick={() => removeQuizFromStandard(standard.id || "", quiz.id || "")}
                  >
                    Remove
                  </IonButton>
                </IonItem>
              ))}
            </IonList>

            <IonItem>
              <IonLabel position="stacked">Add Quiz Association</IonLabel>
              <div className="ion-margin-top">
                <IonInput
                  placeholder="Enter Quiz ID"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      const input = e.target as HTMLIonInputElement;
                      const quizId = input.value?.toString() || '';
                      if (quizId) {
                        addQuizToStandard(standard.id || "", quizId);
                        input.value = '';
                      }
                    }
                  }}
                />
              </div>
            </IonItem>
          </>}
          <>
            <p>Create Question: <Expandable><CreateQuestion defaultValues={{
              questionType: "FCQ",
              quizID: topicQuizIDs[(standard.grade as keyof typeof topicQuizIDs)][(standard.topic as keyof typeof topicQuizIDs["12"])] || "",
              standardsCodes: [standard.id],
            }}/></Expandable></p>
          </>
          {(structuredQuestions.get(standard.id || "") || []).map((question, index)=>{
            return <div key={index}>
              <DisplayFCQ question={question}/>
              <IonList>
                {getQuizzesForQuestion(question.id).map((quiz) => (
                  <IonItem key={quiz.id}>
                    <IonLabel>
                      <h3>{quiz.name}</h3>
                      <IonNote>ID: {quiz.id}</IonNote>
                    </IonLabel>
                    <IonButton 
                      slot="end"
                      fill="clear"
                      color="danger"
                      onClick={() => removeQuizFromQuestion(question.id, quiz.id || "")}
                    >
                      Remove
                    </IonButton>
                  </IonItem>
                ))}
              </IonList>

              <IonItem>
                <IonLabel position="stacked">Add Quiz Association</IonLabel>
                <div className="ion-margin-top">
                  <IonInput
                    placeholder="Enter Quiz ID"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        const input = e.target as HTMLIonInputElement;
                        const quizId = input.value?.toString() || '';
                        if (quizId) {
                          addQuizToQuestion(question.id, quizId);
                          input.value = '';
                        }
                      }
                    }}
                  />
                </div>
              </IonItem>
            </div>
          })}
        </IonCardContent>
      </IonCard>
    })}
    <IonCard>
      <IonCardHeader><IonCardTitle>Questions without Standards</IonCardTitle></IonCardHeader>
      <IonCardContent>
        {unassignedQuestions.map((question, index)=>{
          return <DisplayFCQ question={question} key={index}/>
        })}
      </IonCardContent>
    </IonCard>
  </>
}

export default CreateStandard;